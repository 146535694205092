//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'
import TemplateCalloutLink from '../../components/elements/templateCalloutLink'

const useCaseData = [
  {
    title: 'Communicate visually with conviction',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description: 'Meet your congregation where they are - on social media',
    descriptionColor: 'white-3',
    image: 'usecaseChurch',
    iconShow: true,
    iconContext: 'church',
    // iconColor: 'white',
    iconfill: 'white',
    iconcolor: 'transparent',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'sunset',
    expandcol: 5,
    buttonText: 'See church templates',
    buttonPath: '/tag/church',
    buttonClass: 'btn-cta-outline',
  },
]

const industryExamples = [
  {
    title: 'Services and sermons',
    titlecolor: 'red-1',
    desccolor: 'black-1',
    image: 'exampleChurch1',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'red-5',
    iconShow: true,
    iconContext: 'book',
    iconFill: 'white',
    iconColor: 'red',
    description:
      'Update your community about your services and sermons as they are available',
  },
  {
    title: 'Activities and groups',
    titlecolor: 'dkorange-1',
    desccolor: 'black-1',
    image: 'exampleChurch2',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'dkorange-4',
    iconShow: true,
    iconContext: 'group',
    iconColor: 'dkorange',
    description:
      'Encourage your congregation to participate in the different activities, ministries, and faith-building groups your church offers',
  },
  {
    title: 'Inspiration',
    titlecolor: 'yellow-1',
    desccolor: 'black-1',
    image: 'exampleChurch3',
    imageShadow: true,
    imageBorderColor: 'white-1',
    lineClass: 'yellow-4',
    iconShow: true,
    iconContext: 'lightbulb',
    iconFill: 'white',
    iconColor: 'yellow',
    description:
      'Nourish your members with uplifting quotes, scripture, and faith-promoting invitations',
  },
  // {
  //   title: 'Lifestyle content',
  //   titlecolor: 'green-1',
  //   desccolor: 'black-1',
  //   image: 'exampleGym4',
  //   imageShadow: true,
  //   imageBorderColor: 'white-1',
  //   // lineClass: 'green-4',
  //   iconShow: true,
  //   iconContext: 'lightbulb',
  //   iconColor: 'green',
  //   description:
  //     'Engage your customers with visual lifestyle content and tips & tricks - all easily customized via templates',
  // },
]

const featureData = {
  title: 'Keep your congregation up-to-date with visual content',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Services and sermons',
          titlecolor: 'red-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'red-1',
          iconname: 'book',
          iconcolor: 'red',
          path: '/platform/offers-promotions/',
          description:
            'Update your community about your services and sermons as they are available',
        },
        {
          title: 'Activities and groups',
          titlecolor: 'dkorange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkorange-1',
          iconname: 'group',
          iconcolor: 'dkorange',
          path: '/platform/content-marketing/',
          description:
            'Encourage your congregation to participate in the different activities, ministries, and faith-building groups your church offers',
        },
        {
          title: 'Inspiration',
          titlecolor: 'yellow-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'yellow-1',
          iconname: 'lightbulb',
          iconcolor: 'yellow',
          path: '/platform/review-management/',
          description:
            'Nourish your members with uplifting quotes, scripture, and faith-promoting invitations',
        },
      ],
    },
  ],
}

const templateCallout = {
  cta: 'Connect with your congregation',
  noun: '',
  adjective: 'church',
  path: '/tag/church',
  description: 'See church templates from our community',
}

const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Churches - Visual Content Communication"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you nurture your faith community with visual content communication for churches',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium dkorange-1">
            Nurture your faith community on social
          </h1>
          <p class="display-4">
            Communicate visually to your congregation with templated, designed
            social content
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    {/* <FeatureQuad featureData={featureData} /> */}
    <AlternatingCallout shift={true} featureData={industryExamples} />
    {/* END SELECTED FEATURES */}
    <TemplateCalloutLink data={templateCallout} />
  </Layout>
)

export default About
